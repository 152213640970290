import './IntegrPage.scss';
import { Row, Col, message, Select } from 'antd';
import Button from '../../components/Button/Button';
import { motion } from 'framer-motion';
import pageEnterAnimProps from '../../funcs/pageEnterAnimProps';
import SaveIcon from '../../icons/SaveIcon/SaveIcon';
import Input from '../../components/Input/Input';
import DropCollapse from '../../components/DropCollapse/DropCollapse';
import Checkbox from '../../components/Checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intService from '../../services/intService';
import Loader from '../../components/Loader/Loader';
import { catalogUpdate } from '../../store/actions';
import catService from '../../services/catService';
import switchCrm from '../../funcs/switchCrm';
import orgService from '../../services/orgService';
import Modal from 'antd/lib/modal/Modal';

const is = new intService();
const cs = new catService();
const os = new orgService();

const IntegrPage = () => {
    const dispatch = useDispatch();
    const { token, settings, city } = useSelector((state) => state);
    const [saveLoad, setSaveLoad] = useState(false);
    const [intLoad, setIntLoad] = useState(false);
    const [hooksLoad, setHooksLoad] = useState(false);
    const [pageLoad, setPageLoad] = useState(true);
    const [AuthBotToken, setAuthBotToken] = useState('');
    const [AutoSaveOrganisations, setAutoSaveOrganisations] = useState('');
    const [AutoSavePlates, setAutoSavePlates] = useState('');
    const [CanCreateNewOrganisations, setCanCreateNewOrganisations] = useState('');
    const [CanCreateNewPlates, setCanCreateNewPlates] = useState('');
    const [PaymentSystemToken, setPaymentSystemToken] = useState('');
    const [PaymentSystemType, setPaymentSystemType] = useState();
    const [smsService, setSmsService] = useState('');
    const [smsServicesList, setSmsServicesList] = useState([]);
    const [SMSruToken, setSMSruToken] = useState('');
    const [iikoCloudApi, setiikoCloudApi] = useState('');
    const [iikoMenuType, setIikoMenuType] = useState(0);
    const iikoMenuTypesList = [
        { label: 'Из чейна', value: 0 },
        { label: 'Веб меню', value: 1 },
    ];
    const [externalMenuValue, setExternalMenuValue] = useState('');
    const [externalMenus, setExternalMenus] = useState([]);

    const [priceCategoryValue, setPriceCategoryValue] = useState('');
    const [priceCategoriesList, setPriceCategoriesList] = useState([]);

    const [bonusWalletValue, setBonusWalletValue] = useState('');
    const [bonusWalletList, setBonusWalletList] = useState([]);

    const [paymentTypes, setPaymentTypes] = useState([]);

    const [iikoTargetGroup, setiikoTargetGroup] = useState('');
    const [senderId, setSenderId] = useState('');
    const [iikoTargetID, setiikoTargetID] = useState('');
    const [IIkoIDList, setIIkoIDList] = useState();

    useEffect(() => {
        if (token) {
            setPageLoad(true);
            is.getIntSettings(token, { cityId: city.id })
                .then((res) => {
                    if (res) {
                        setAuthBotToken(res.AuthBotToken);
                        setAutoSaveOrganisations(res.AutoSaveOrganisations);
                        setAutoSavePlates(res.AutoSavePlates);
                        setCanCreateNewOrganisations(res.CanCreateNewOrganisations);
                        setCanCreateNewPlates(res.CanCreateNewPlates);
                        setPaymentSystemToken(res.PaymentSystemToken);
                        // if(res.PaymentSystemType == 'Rus_Standart') {
                        //     setPaymentSystemType('Русский стандарт')
                        // } else {
                        setPaymentSystemType(Number(res.PaymentSystemType));
                        // }
                        setSMSruToken(res.SMSruToken);
                        setiikoCloudApi(res.iikoCloudApi);
                        setiikoTargetGroup(res.iikoTargetGroup);
                        setiikoTargetID(res.iikoTargetID);
                        setSenderId(res.sms_sender_id);
                        setSmsService(res.sms_service);
                        setIikoMenuType(res?.iikoMenu);
                        setExternalMenuValue(res?.menuId);
                        setPriceCategoryValue(res?.priceCategoriesId);
                        setBonusWalletValue(res?.bonus_wallet_id);
                    }
                })
                .finally((_) => {
                    setPageLoad(false);
                });
            is.getSmsServices(token).then((res) => {
                if (res) {
                    setSmsServicesList(
                        res.map((service) => ({
                            value: service.service,
                        }))
                    );
                }
            });
            is.getPaymentTypes(token).then((res) => {
                if (res && !res.error) {
                    setPaymentTypes(
                        res.map((item) => ({ label: item.payment_type, value: item.id }))
                    );
                }
            });
            is.getMenuPanelIIKO(token).then((res) => {
                if (res && !res.error) {
                    setExternalMenus(
                        res.externalMenus?.map((item) => ({
                            label: item.name,
                            value: item.id,
                        }))
                    );
                    setPriceCategoriesList(
                        res.priceCategories?.map((item) => ({
                            label: item.name,
                            value: item.id,
                        }))
                    );
                }
            });
            if (settings?.cms_type === 'iiko') {
                os.getIikoData(token, { action: 'getOrganisations' }).then((res) => {
                    setIIkoIDList(
                        res?.organizations?.map((item) => ({
                            label: item?.name,
                            value: item?.id,
                        }))
                    );
                });
            }

            is.getGeustWalletsIIKO(token).then((res) => {
                setBonusWalletList(
                    res?.Programs?.filter(
                        (item) => item?.isActive && item?.programType == 1
                    )?.map((item) => ({
                        label: item?.name,
                        value: item?.walletId,
                    }))
                );
            });
        }
    }, [token, city]);

    const onSave = () => {
        setSaveLoad(true);
        const body = {
            AuthBotToken,
            AutoSaveOrganisations,
            AutoSavePlates,
            CanCreateNewOrganisations,
            CanCreateNewPlates,
            PaymentSystemToken,
            SMSruToken,
            iikoCloudApi,
            iikoTargetID,
            iikoTargetGroup,
            PaymentSystemType:
                PaymentSystemType == 'Русский стандарт'
                    ? 'Rus_Standart'
                    : PaymentSystemType,
            sms_sender_id: senderId,
            sms_service: smsService,
            iikoMenu: iikoMenuType,
            menuId: iikoMenuType ? externalMenuValue || null : null,
            priceCategoriesId: iikoMenuType ? priceCategoryValue || null : null,
            bonus_wallet_id: bonusWalletValue,
            cityId: city.id,
        };

        is.editIntSettings(token, body)
            .then((res) => {
                setAuthBotToken(res.AuthBotToken);
                setAutoSaveOrganisations(res.AutoSaveOrganisations);
                setAutoSavePlates(res.AutoSavePlates);
                setCanCreateNewOrganisations(res.CanCreateNewOrganisations);
                setCanCreateNewPlates(res.CanCreateNewPlates);
                setPaymentSystemToken(res.PaymentSystemToken);
                // if(res.PaymentSystemType == 'Rus_Standart') {
                //     setPaymentSystemType('Русский стандарт')
                // } else {
                setPaymentSystemType(Number(res.PaymentSystemType));
                // }

                setSMSruToken(res.SMSruToken);
                setiikoCloudApi(res.iikoCloudApi);

                setiikoTargetID(res.iikoTargetID);
                setiikoTargetGroup(res.iikoTargetGroup);
                setSenderId(res.sms_sender_id);
                setSmsService(res.sms_service);
                setBonusWalletValue(res?.bonus_wallet_id);
            })
            .finally((_) => {
                setSaveLoad(false);
                message.success('Настройки сохранены');
            });
    };

    const startInt = () => {
        setIntLoad(true);
        const body = {
            iikoMenu: iikoMenuType,
            menuId: iikoMenuType ? externalMenuValue || null : null,
            priceCategoriesId: iikoMenuType ? priceCategoryValue || null : null,
            cityId: city.id,
        };
        is.startInt(token, body)
            .then((res) => {
                if (res?.error == '1') {
                    message.error(res?.message);
                } else {
                    message.success(res?.message);
                    cs.getCats(token, { cityId: city.id }).then((res) => {
                        dispatch(catalogUpdate(res));
                    });
                }
            })
            .finally((_) => setIntLoad(false));
    };

    const selectPm = (item) => {
        setPaymentSystemType(item);
    };
    const selectSmsService = (item) => {
        setSmsService(item);
    };

    const [webHooksOpen, setWebHooksOpen] = useState(false);
    const [webHook, setWebHook] = useState();

    const closeWebHooks = () => {
        setWebHooksOpen(false);
    };

    const openWebHooks = () => {
        setWebHooksOpen(true);
        const body = {
            organisation_id: iikoTargetID,
        };
        setHooksLoad(true);
        is.getWebHooks(token, body)
            .then((res) => {
                if (res?.webHooksUri) setWebHook(res);
            })
            .finally((_) => setHooksLoad(false));
    };

    if (pageLoad) {
        return (
            <div className='page'>
                <div className='pageBody'>
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <motion.div {...pageEnterAnimProps} className='IntegrPage page'>
            <div className='pageBody'>
                <div className='IntegrPage__body pageBody-content'>
                    <Row gutter={[40, 0]}>
                        <Col span={12}>
                            <Row gutter={[0, 25]}>
                                <Col span={24}>
                                    <Row gutter={[0, 10]}>
                                        <Col span={24}>
                                            <div className='def-label'>
                                                Платежная система
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <Select
                                                className='EditHr__select shadow'
                                                placeholder='Платежная система'
                                                popupClassName='EditHr__list'
                                                options={paymentTypes}
                                                value={PaymentSystemType}
                                                onSelect={selectPm}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Input
                                                maskType={String}
                                                placeholder={'API key'}
                                                value={PaymentSystemToken}
                                                onChange={(e) =>
                                                    setPaymentSystemToken(
                                                        e?.target?.value
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[0, 10]}>
                                        <Col span={24}>
                                            <div className='def-label'>SMS API key</div>
                                        </Col>
                                        <Col span={24}>
                                            <Input
                                                maskType={String}
                                                placeholder={'API key'}
                                                value={SMSruToken}
                                                onChange={(e) =>
                                                    setSMSruToken(e?.target?.value)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Input
                                        maskType={String}
                                        placeholder={'sender id'}
                                        value={senderId}
                                        onChange={(e) => setSenderId(e?.target?.value)}
                                    />
                                </Col>
                                <Col span={24}>
                                    <div className='def-label'>СМС сервис</div>
                                    <DropCollapse
                                        styles={{ margin: 0 }}
                                        value={smsService || smsServicesList[0]?.value}
                                        placeholder='СМС сервис'
                                        list={smsServicesList}
                                        selectItem={selectSmsService}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[0, 10]}>
                                        <Col span={24}>
                                            <div className='def-label'>
                                                Токен бота телеграм для авторизации
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <Input
                                                maskType={String}
                                                placeholder={'Token'}
                                                value={AuthBotToken}
                                                onChange={(e) =>
                                                    setAuthBotToken(e.target.value)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Button
                                        load={saveLoad}
                                        onClick={onSave}
                                        text={'Сохранить'}
                                        before={<SaveIcon size={20} color={'#fff'} />}
                                        styles={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={12} style={{ marginTop: '38px' }}>
                            <Row gutter={[0, 25]}>
                                {switchCrm(
                                    settings,
                                    <Col span={24}>
                                        <Row gutter={[0, 10]}>
                                            <Col span={24}>
                                                <div className='def-label'>
                                                    iIko Cloud API token
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Input
                                                    placeholder={'Iiko API key'}
                                                    maskType={String}
                                                    value={iikoCloudApi}
                                                    onChange={(e) =>
                                                        setiikoCloudApi(e.target.value)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>,
                                    <Col span={24}>
                                        <Row gutter={[0, 10]}>
                                            <Col span={24}>
                                                <div className='def-label'>
                                                    RKeeper Cloud API token
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Input
                                                    placeholder={'RKeeper API key'}
                                                    maskType={String}
                                                    value={iikoCloudApi}
                                                    onChange={(e) =>
                                                        setiikoCloudApi(e.target.value)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>,
                                    <Col span={24}>
                                        <Row gutter={[0, 10]}>
                                            <Col span={24}>
                                                <div className='def-label'>
                                                    1C Cloud API token
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Input
                                                    placeholder={'1C API key'}
                                                    maskType={String}
                                                    value={iikoCloudApi}
                                                    onChange={(e) =>
                                                        setiikoCloudApi(e.target.value)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>,
                                    <Col span={24}>
                                        <Row gutter={[0, 10]}>
                                            <Col span={24}>
                                                <div className='def-label'>
                                                    FrontPad Cloud API token
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Input
                                                    placeholder={'FrontPad API key'}
                                                    maskType={String}
                                                    value={iikoCloudApi}
                                                    onChange={(e) =>
                                                        setiikoCloudApi(e.target.value)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                {settings?.cms_type == 'iiko' ? (
                                    <>
                                        <Col span={24}>
                                            <Select
                                                className='EditHr__select shadow'
                                                placeholder='ID точки продаж для выгрузки'
                                                popupClassName='EditHr__list'
                                                options={IIkoIDList}
                                                value={iikoTargetID || null}
                                                onSelect={(value) => {
                                                    setiikoTargetID(value);
                                                }}
                                            />
                                            {/* <Input
                                                style={{ marginTop: '10px' }}
                                                maskType={String}
                                                placeholder={'ID группы для выгрузки'}
                                                value={iikoTargetGroup}
                                                onChange={(e) =>
                                                    setiikoTargetGroup(e.target.value)
                                                }
                                            /> */}
                                        </Col>
                                    </>
                                ) : null}
                                <Col span={24}>
                                    <Col span={24}>
                                        <div className='def-label'>
                                            Бонусный баланс гостя
                                        </div>
                                    </Col>
                                    <Select
                                        className='EditHr__select shadow'
                                        placeholder='Бонусный баланс гостя'
                                        popupClassName='EditHr__list'
                                        options={bonusWalletList}
                                        value={bonusWalletValue || null}
                                        onSelect={(value) => {
                                            setBonusWalletValue(value);
                                        }}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Col span={24}>
                                        <div className='def-label'>Меню</div>
                                    </Col>
                                    <Select
                                        className='EditHr__select shadow'
                                        placeholder='Меню'
                                        popupClassName='EditHr__list'
                                        options={iikoMenuTypesList}
                                        value={iikoMenuType >= 0 ? iikoMenuType : null}
                                        onSelect={(value) => {
                                            setIikoMenuType(value);
                                        }}
                                    />
                                </Col>
                                {iikoMenuType?.toString() === '1' && (
                                    <>
                                        <Col span={24}>
                                            <Col span={24}>
                                                <div className='def-label'>
                                                    Выбор меню
                                                </div>
                                            </Col>
                                            <Select
                                                className='EditHr__select shadow'
                                                placeholder='Выбор меню'
                                                popupClassName='EditHr__list'
                                                options={externalMenus}
                                                value={externalMenuValue || null}
                                                onSelect={(value) => {
                                                    setExternalMenuValue(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Col span={24}>
                                                <div className='def-label'>
                                                    Выбор ценовой категории
                                                </div>
                                            </Col>
                                            <Select
                                                className='EditHr__select shadow'
                                                placeholder='Выбор ценовой категории'
                                                popupClassName='EditHr__list'
                                                options={priceCategoriesList}
                                                value={priceCategoryValue || null}
                                                onSelect={(value) => {
                                                    setPriceCategoryValue(value);
                                                }}
                                            />
                                        </Col>
                                    </>
                                )}
                                {switchCrm(
                                    settings,
                                    <Col span={24}>
                                        <Checkbox
                                            text={
                                                'Автоматически загружать из iIko организации'
                                            }
                                            checked={AutoSaveOrganisations == '1'}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setAutoSaveOrganisations('1');
                                                } else {
                                                    setAutoSaveOrganisations('0');
                                                }
                                            }}
                                            id={'AutoSaveOrganisations'}
                                        />
                                    </Col>,
                                    <Col span={24}>
                                        <Checkbox
                                            text={
                                                'Автоматически загружать из RKeeper организации'
                                            }
                                            checked={AutoSaveOrganisations == '1'}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setAutoSaveOrganisations('1');
                                                } else {
                                                    setAutoSaveOrganisations('0');
                                                }
                                            }}
                                            id={'AutoSaveOrganisations'}
                                        />
                                    </Col>,
                                    <Col span={24}>
                                        <Checkbox
                                            text={
                                                'Автоматически загружать из 1C организации'
                                            }
                                            checked={AutoSaveOrganisations == '1'}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setAutoSaveOrganisations('1');
                                                } else {
                                                    setAutoSaveOrganisations('0');
                                                }
                                            }}
                                            id={'AutoSaveOrganisations'}
                                        />
                                    </Col>,
                                    <Col span={24}>
                                        <Checkbox
                                            text={
                                                'Автоматически загружать из FrontPad организации'
                                            }
                                            checked={AutoSaveOrganisations == '1'}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setAutoSaveOrganisations('1');
                                                } else {
                                                    setAutoSaveOrganisations('0');
                                                }
                                            }}
                                            id={'AutoSaveOrganisations'}
                                        />
                                    </Col>
                                )}
                                {iikoMenuType?.toString() === '0' &&
                                    switchCrm(
                                        settings,
                                        <Col span={24}>
                                            <Checkbox
                                                text={
                                                    'Разрешить iIko создание новых организации'
                                                }
                                                checked={CanCreateNewOrganisations == '1'}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCanCreateNewOrganisations('1');
                                                    } else {
                                                        setCanCreateNewOrganisations('0');
                                                    }
                                                }}
                                                id={'CanCreateNewOrganisations'}
                                            />
                                        </Col>,
                                        <Col span={24}>
                                            <Checkbox
                                                text={
                                                    'Разрешить RKeeper создание новых организации'
                                                }
                                                checked={CanCreateNewOrganisations == '1'}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCanCreateNewOrganisations('1');
                                                    } else {
                                                        setCanCreateNewOrganisations('0');
                                                    }
                                                }}
                                                id={'CanCreateNewOrganisations'}
                                            />
                                        </Col>,
                                        <Col span={24}>
                                            <Checkbox
                                                text={
                                                    'Разрешить 1C создание новых организации'
                                                }
                                                checked={CanCreateNewOrganisations == '1'}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCanCreateNewOrganisations('1');
                                                    } else {
                                                        setCanCreateNewOrganisations('0');
                                                    }
                                                }}
                                                id={'CanCreateNewOrganisations'}
                                            />
                                        </Col>,
                                        <Col span={24}>
                                            <Checkbox
                                                text={
                                                    'Разрешить FrontPad создание новых организации'
                                                }
                                                checked={CanCreateNewOrganisations == '1'}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setCanCreateNewOrganisations('1');
                                                    } else {
                                                        setCanCreateNewOrganisations('0');
                                                    }
                                                }}
                                                id={'CanCreateNewOrganisations'}
                                            />
                                        </Col>
                                    )}
                                <Col span={24}>
                                    {switchCrm(
                                        settings,
                                        <Button
                                            load={intLoad}
                                            onClick={startInt}
                                            text={'Синхронизировать все данные с iIko'}
                                            styles={{ width: '100%' }}
                                        />,
                                        <Button
                                            load={intLoad}
                                            onClick={startInt}
                                            text={'Синхронизировать все данные с RKeeper'}
                                            styles={{ width: '100%' }}
                                        />,
                                        <Button
                                            load={intLoad}
                                            onClick={startInt}
                                            text={'Синхронизировать все данные с 1C'}
                                            styles={{ width: '100%' }}
                                        />,
                                        <Button
                                            load={intLoad}
                                            onClick={startInt}
                                            text={
                                                'Синхронизировать все данные с FrontPad'
                                            }
                                            styles={{ width: '100%' }}
                                        />
                                    )}
                                    <Button
                                        load={hooksLoad}
                                        disabled={!iikoTargetID}
                                        onClick={openWebHooks}
                                        text={'Посмотреть WebHook URL'}
                                        styles={{ width: '100%', marginTop: '16px' }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                className='Modal'
                width={700}
                open={webHooksOpen}
                onCancel={closeWebHooks}
                title={'WebHook URL'}
            >
                {webHook?.webHooksUri}
            </Modal>
        </motion.div>
    );
};
export default IntegrPage;

import { Button, Modal, Upload } from 'antd';
import { useEffect, useState } from 'react';
import endpoints from '../../services/endpoints';
import checkAuth from '../../services/checkAuth';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { TiPlusOutline } from 'react-icons/ti';
import { BiPlus } from 'react-icons/bi';
import Input from '../../components/Input/Input';
import SaveIcon from '../../icons/SaveIcon/SaveIcon';
import checkDom from '../../funcs/checkDom';
import './Banners.scss';
import Btn from '../../components/Button/Button';
import { motion } from 'framer-motion';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';
import Checkbox from '../../components/Checkbox/Checkbox';

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

const asyncRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess('ok');
    }, 0);
};

export const Banners = (props) => {
    const { token, settings, settingsFront, city } = useSelector((state) => state);
    const [data, setData] = useState([]);
    const [squareBannersData, setSquareBannersData] = useState([]);
    const [rectangularBannersData, setRectangularBannersData] = useState([]);
    const [image, setImage] = useState('');
    const [tempImage, setTempImage] = useState('');
    const [bannerText, setBannerText] = useState('');
    const [editImage, setEditImage] = useState('');
    const [tempEditImage, setTempEditImage] = useState('');
    const [bannerEditText, setBannerEditText] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSquareBannerModalOpen, setIsSquareBannerModalOpen] = useState(false);
    const [isRectangularBannerModalOpen, setIsRectangularBannerModalOpen] =
        useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [localId, setLocalId] = useState('');
    const [localImage, setLocalImage] = useState('');
    const [localText, setLocalText] = useState('');
    const [localUrl, setLocalUrl] = useState('');
    const [showInApp, setShowInApp] = useState(true);
    const [showInSite, setShowInSite] = useState(true);

    const hideInFunc = () => {
        if (showInApp && showInSite) {
            return 3;
        } else if (!showInApp && !showInSite) {
            return 0;
        } else if (showInApp) {
            return 1;
        } else if (showInSite) {
            return 2;
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showSquareBannerModal = () => {
        setIsSquareBannerModalOpen(true);
    };
    const showRectangularBannerModal = () => {
        setIsRectangularBannerModalOpen(true);
    };

    const handleEditBanner = (id, image, text, url, showInApp, showInSite) => {
        setIsEditModalOpen(true);
        setLocalId(id);
        setLocalImage(image);
        setLocalText(text);
        setLocalUrl(url);
        setShowInApp(!!showInApp);
        setShowInSite(!!showInSite);
    };
    const handleCancelEditBanner = () => {
        setIsEditModalOpen(false);
    };

    const handleSave = () => {
        addBanner();
        setImage('');
        setTempImage('');
        setBannerText('');
        setBannerUrl('');
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleSquareBannerSave = () => {
        addSquareBanner();
        setImage('');
        setTempImage('');
        setBannerText('');
        setBannerUrl('');
        setIsModalOpen(false);
        setShowInApp(true);
        setShowInSite(true);
    };

    const handleSquareBannerCancel = () => {
        setIsSquareBannerModalOpen(false);
        handleCancel();
    };

    const handleRectangularBannerSave = () => {
        addRectangularBanner();
        setImage('');
        setTempImage('');
        setBannerText('');
        setBannerUrl('');
        setIsModalOpen(false);
        setShowInApp(true);
        setShowInSite(true);
    };

    const handleRectangularBannerCancel = () => {
        setIsRectangularBannerModalOpen(false);
        handleCancel();
    };

    const fetchData = async () => {
        try {
            await fetch(endpoints.getBanners, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    cityId: city.id,
                },
            }).then(async (res) => {
                const d = await checkAuth(res);

                setData(d);
            });
        } catch (err) {
            console.log(err);
        }
    };
    const fetchSquareBannersData = async () => {
        try {
            const formData = new FormData();
            formData.append('type', 1);
            formData.append('cityId', city.id);
            await fetch(endpoints.getBanners, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async (res) => {
                const d = await checkAuth(res);
                setSquareBannersData(d);
            });
        } catch (err) {
            console.log(err);
        }
    };
    const fetchRectangularBannersData = async () => {
        try {
            const formData = new FormData();
            formData.append('type', 0);
            formData.append('cityId', city.id);
            await fetch(endpoints.getBanners, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(async (res) => {
                const d = await checkAuth(res);
                setRectangularBannersData(d);
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
        settingsFront?.BannersSquare && fetchSquareBannersData();
        settingsFront?.BannersRectangular && fetchRectangularBannersData();
    }, []);

    const addBanner = async () => {
        try {
            const formData = new FormData();

            formData.append('width', 250);
            formData.append('height', 150);
            formData.append('image', image);
            formData.append('cityId', city.id);
            formData.append('text', bannerText);
            formData.append('hideIn', hideInFunc());

            let res = await fetch(endpoints.addBanners, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const d = await checkAuth(res);
        } catch (err) {
            console.log(err);
        }
        fetchData();
    };

    const addSquareBanner = async () => {
        try {
            const formData = new FormData();

            formData.append('width', 250);
            formData.append('height', 150);
            formData.append('image', image);
            formData.append('type', 1);
            formData.append('text', bannerText);
            formData.append('url', bannerUrl);
            formData.append('cityId', city.id);
            formData.append('hideIn', hideInFunc());

            let res = await fetch(endpoints.addBanners, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const d = await checkAuth(res);
        } catch (err) {
            console.log(err);
        }
        fetchSquareBannersData();
    };
    const addRectangularBanner = async () => {
        try {
            const formData = new FormData();

            formData.append('width', 250);
            formData.append('height', 150);
            formData.append('image', image);
            formData.append('text', bannerText);
            formData.append('url', bannerUrl);
            formData.append('type', 0);
            formData.append('cityId', city.id);
            formData.append('hideIn', hideInFunc());

            let res = await fetch(endpoints.addBanners, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const d = await checkAuth(res);
        } catch (err) {
            console.log(err);
        }
        fetchRectangularBannersData();
    };

    const editBanner = async (id) => {
        try {
            const formData = new FormData();

            formData.append('width', 250);
            formData.append('height', 150);
            formData.append('image', editImage);
            formData.append('text', bannerEditText);
            formData.append('cityId', city.id);
            formData.append('hideIn', hideInFunc());

            let res = await fetch(endpoints.addBanners, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const d = await checkAuth(res);
            fetchData();
        } catch (err) {
            console.log(err);
        }
    };

    const editMamaBanner = async (id) => {
        try {
            const formData = new FormData();

            formData.append('ID', id);
            if (editImage) {
                formData.append('image', editImage);
            }
            formData.append('text', localText);
            formData.append('url', localUrl);
            formData.append('city', city.id);

            let res = await fetch(endpoints.editBanner, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const d = await checkAuth(res);
            handleCancelEditBanner();
        } catch (err) {
            console.log(err);
        }
        fetchSquareBannersData();
        fetchRectangularBannersData();
    };

    const handleUpload = async (info) => {
        if (info.file.status === 'uploading') {
            // setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            const base64 = await toBase64(info.file.originFileObj);
            const blobImg = URL.createObjectURL(info.file.originFileObj);
            setTempImage(blobImg);
            setImage(base64);
        }
    };
    const handleEditUpload = async (info) => {
        if (info.file.status === 'uploading') {
            // setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            const base64 = await toBase64(info.file.originFileObj);
            const blobImg = URL.createObjectURL(info.file.originFileObj);
            setTempEditImage(blobImg);
            setEditImage(base64);
        }
    };

    const handleDelete = async (id) => {
        try {
            const formData = new FormData();
            formData.append('ID', id);

            let res = await fetch(endpoints.removeBanners, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const d = await checkAuth(res);
            fetchData();
            fetchSquareBannersData();
            fetchRectangularBannersData();
        } catch (err) {
            console.log(err);
        }
    };

    const handleSquareBannerOrderChange = (
        sourceId,
        sourceIndex,
        targetIndex,
        targetId
    ) => {
        if (sourceIndex === squareBannersData.length) {
            return;
        } else {
            const nextState = swap(squareBannersData, sourceIndex, targetIndex);
            setSquareBannersData(nextState);
        }
    };
    const handleRectangularBannerOrderChange = (
        sourceId,
        sourceIndex,
        targetIndex,
        targetId
    ) => {
        if (sourceIndex === rectangularBannersData.length) {
            return;
        } else {
            const nextState = swap(rectangularBannersData, sourceIndex, targetIndex);
            setRectangularBannersData(nextState);
        }
    };
    const handleDataBannerOrderChange = (
        sourceId,
        sourceIndex,
        targetIndex,
        targetId
    ) => {
        if (sourceIndex === data.length) {
            return;
        } else {
            const nextState = swap(data, sourceIndex, targetIndex);
            setData(nextState);
        }
    };

    useEffect(() => {
        setEditImage();
    }, [isEditModalOpen]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className='page'
        >
            <div className='pageBody'>
                <div className='pageBody-content'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '50px',
                        }}
                    >
                        {settingsFront?.BannersSquare && (
                            <Btn
                                styles={{ marginRight: '20px' }}
                                text={'Квадратные баннеры'}
                                onClick={showSquareBannerModal}
                            ></Btn>
                        )}
                        <Modal
                            open={isSquareBannerModalOpen}
                            onCancel={handleSquareBannerCancel}
                            footer={
                                <Button
                                    onClick={handleSquareBannerCancel}
                                    danger
                                    type={'primary'}
                                    style={{ marginLeft: 'auto', display: 'block' }}
                                >
                                    Закрыть
                                </Button>
                            }
                            width={1200}
                            style={{ paddingRight: '20px' }}
                        >
                            <h2>Редактировать квадратные баннеры</h2>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <GridContextProvider
                                    onChange={handleSquareBannerOrderChange}
                                >
                                    <GridDropZone
                                        boxesPerRow={4}
                                        rowHeight={350}
                                        style={{
                                            // height: squareBannersData.length > 0 ? "1000px" : "200px",
                                            width: '100%',
                                            height: '70vh',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        {squareBannersData?.map((c, i) => (
                                            <GridItem key={i}>
                                                <div
                                                    style={{
                                                        // width: c.width,
                                                        height: '250px',
                                                        display: 'block',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            border: '2px solid gray',
                                                            // width: c.width,
                                                            // height: c.height,
                                                            width: '250px',
                                                            height: '100%',
                                                            padding: 12,
                                                            borderRadius: '12px',
                                                            position: 'relative',
                                                            margin: 12,
                                                            pointerEvents: 'none',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: -12,
                                                                cursor: 'pointer',
                                                                right: -12,
                                                                background: '#DC143C',
                                                                height: 26,
                                                                width: 26,
                                                                borderRadius: '50%',
                                                                color: 'white',
                                                                fontWeight: 500,
                                                                textAlign: 'center',
                                                                lineHeight: '24px',
                                                                pointerEvents: 'auto',
                                                            }}
                                                            onClick={() =>
                                                                handleDelete(c.ID)
                                                            }
                                                        >
                                                            x
                                                        </div>
                                                        <img
                                                            src={`${c.image}`}
                                                            width={'100%'}
                                                            height={'100%'}
                                                            style={{
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    </div>
                                                    <Btn
                                                        styles={{ width: '95%' }}
                                                        justify={'center'}
                                                        text={'Редактировать'}
                                                        onClick={() =>
                                                            handleEditBanner(
                                                                c.ID,
                                                                c.image,
                                                                c.text,
                                                                c.url,
                                                                c.hideInApp,
                                                                c.hideInSite
                                                            )
                                                        }
                                                    ></Btn>
                                                </div>
                                            </GridItem>
                                        ))}
                                        <GridItem>
                                            <button
                                                onClick={showModal}
                                                style={{
                                                    height: '350px',
                                                    border: '1px dashed #aaa',
                                                    background: 'none',
                                                    outline: 'none',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#eee',
                                                    padding: '90px 50px 140px',
                                                }}
                                                type='button'
                                            >
                                                <BiPlus size={'24px'} color={'#7B99FF'} />
                                                <div
                                                    style={{
                                                        color: '#7B99FF',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Добавить баннер
                                                </div>
                                            </button>
                                        </GridItem>
                                    </GridDropZone>
                                </GridContextProvider>
                            </div>
                            <Modal
                                title='Редактировать баннер'
                                open={isEditModalOpen}
                                onCancel={handleCancelEditBanner}
                                width={480}
                                footer={[
                                    <button
                                        type={'button'}
                                        onClick={() => editMamaBanner(localId)}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(123, 153, 255)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Сохранить
                                    </button>,
                                    <button
                                        type={'button'}
                                        onClick={handleCancelEditBanner}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(255, 77, 79)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Отмена
                                    </button>,
                                ]}
                            >
                                {editImage || localImage ? (
                                    <div className='banners__pic-item'>
                                        <img src={editImage || localImage} alt='' />
                                        <div className='baners__pic-item--backdrop'>
                                            <Upload
                                                className='baners__pic-item--new'
                                                showUploadList={false}
                                                customRequest={asyncRequest}
                                                listType='picture-card'
                                                onChange={handleEditUpload}
                                            >
                                                <p>Загрузить другую картинку</p>
                                            </Upload>
                                        </div>
                                    </div>
                                ) : (
                                    <Upload
                                        showUploadList={false}
                                        customRequest={asyncRequest}
                                        listType='picture-card'
                                        onChange={handleEditUpload}
                                    >
                                        <p
                                            style={{
                                                height: '150px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Загрузить картинку
                                        </p>
                                    </Upload>
                                )}

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={localText}
                                    onChange={(e) => setLocalText(e.target.value)}
                                    placeholder='Название'
                                    style={{ marginTop: '20px' }}
                                />

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={localUrl}
                                    onChange={(e) => setLocalUrl(e.target.value)}
                                    placeholder='URL'
                                    style={{ marginTop: '20px' }}
                                />
                                <Checkbox
                                    text={'Показывать в приложении'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInApp}
                                    onChange={(e) => setShowInApp(e.target.checked)}
                                    id={'show-app-edit'}
                                />
                                <Checkbox
                                    text={'Показывать на сайте'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInSite}
                                    onChange={(e) => setShowInSite(e.target.checked)}
                                    id={'show-site-edit'}
                                />
                            </Modal>
                            <Modal
                                title='Добавить квадратный баннер'
                                open={isModalOpen}
                                onCancel={handleCancel}
                                width={480}
                                style={{ maxHeight: '10vh' }}
                                footer={[
                                    <button
                                        type={'button'}
                                        onClick={handleSquareBannerSave}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(123, 153, 255)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Сохранить
                                    </button>,
                                    <button
                                        type={'button'}
                                        onClick={handleCancel}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(255, 77, 79)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Отмена
                                    </button>,
                                ]}
                            >
                                {tempImage ? (
                                    <div className='banners__pic-item'>
                                        <img src={tempImage} alt='' />
                                        <div className='baners__pic-item--backdrop'>
                                            <Upload
                                                className='baners__pic-item--new'
                                                showUploadList={false}
                                                customRequest={asyncRequest}
                                                listType='picture-card'
                                                onChange={handleUpload}
                                            >
                                                <p>Загрузить другую картинку</p>
                                            </Upload>
                                        </div>
                                    </div>
                                ) : (
                                    <Upload
                                        showUploadList={false}
                                        customRequest={asyncRequest}
                                        listType='picture-card'
                                        onChange={handleUpload}
                                    >
                                        <p
                                            style={{
                                                height: '150px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Загрузить картинку
                                        </p>
                                    </Upload>
                                )}

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={bannerText}
                                    onChange={(e) => setBannerText(e.target.value)}
                                    placeholder='Название'
                                    style={{ marginTop: '20px' }}
                                />

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={bannerUrl}
                                    onChange={(e) => setBannerUrl(e.target.value)}
                                    placeholder='URL'
                                    style={{ marginTop: '20px' }}
                                />
                                <Checkbox
                                    text={'Показывать в приложении'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInApp}
                                    onChange={(e) => setShowInApp(e.target.checked)}
                                    id={'show-app'}
                                />
                                <Checkbox
                                    text={'Показывать на сайте'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInSite}
                                    onChange={(e) => setShowInSite(e.target.checked)}
                                    id={'show-site'}
                                />
                            </Modal>
                        </Modal>

                        {settingsFront?.BannersRectangular && (
                            <Btn
                                styles={{ display: 'inline-flex' }}
                                text={'Прямоугольные баннеры'}
                                onClick={showRectangularBannerModal}
                            ></Btn>
                        )}
                        <Modal
                            open={isRectangularBannerModalOpen}
                            onCancel={handleRectangularBannerCancel}
                            footer={
                                <Button
                                    onClick={handleRectangularBannerCancel}
                                    danger
                                    type={'primary'}
                                    style={{ marginLeft: 'auto', display: 'block' }}
                                >
                                    Закрыть
                                </Button>
                            }
                            width={1200}
                        >
                            <h2>Редактировать прямоугольные баннеры</h2>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <GridContextProvider
                                    onChange={handleRectangularBannerOrderChange}
                                >
                                    <GridDropZone
                                        boxesPerRow={4}
                                        rowHeight={250}
                                        style={{
                                            width: '100%',
                                            height: '70vh',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        {rectangularBannersData?.map((c, i) => (
                                            <GridItem key={i}>
                                                <div
                                                    style={{
                                                        // width: c.width,
                                                        // height: c.height,
                                                        display: 'block',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            border: '2px solid gray',
                                                            // width: c.width,
                                                            height: 150,
                                                            padding: 12,
                                                            borderRadius: '12px',
                                                            position: 'relative',
                                                            margin: 12,
                                                            pointerEvents: 'none',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: -12,
                                                                cursor: 'pointer',
                                                                right: -12,
                                                                background: '#DC143C',
                                                                height: 26,
                                                                width: 26,
                                                                borderRadius: '50%',
                                                                color: 'white',
                                                                fontWeight: 500,
                                                                textAlign: 'center',
                                                                lineHeight: '24px',
                                                                pointerEvents: 'auto',
                                                            }}
                                                            onClick={() =>
                                                                handleDelete(c.ID)
                                                            }
                                                        >
                                                            x
                                                        </div>
                                                        <img
                                                            src={`${c.image}`}
                                                            width={'100%'}
                                                            height={'100%'}
                                                            style={{
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    </div>
                                                    <Btn
                                                        styles={{ width: '95%' }}
                                                        justify={'center'}
                                                        text={'Редактировать'}
                                                        onClick={() =>
                                                            handleEditBanner(
                                                                c.ID,
                                                                c.image,
                                                                c.text,
                                                                c.url,
                                                                c.hideInApp,
                                                                c.hideInSite
                                                            )
                                                        }
                                                    ></Btn>
                                                </div>
                                            </GridItem>
                                        ))}
                                        <GridItem>
                                            <button
                                                onClick={showModal}
                                                style={{
                                                    height: '150px',
                                                    border: '1px dashed #aaa',
                                                    background: 'none',
                                                    outline: 'none',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#eee',
                                                    padding: '40px 50px 60px',
                                                }}
                                                type='button'
                                            >
                                                <BiPlus size={'24px'} color={'#7B99FF'} />
                                                <div
                                                    style={{
                                                        color: '#7B99FF',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Добавить баннер
                                                </div>
                                            </button>
                                        </GridItem>
                                    </GridDropZone>
                                </GridContextProvider>
                            </div>
                            <Modal
                                title='Редактировать баннер'
                                open={isEditModalOpen}
                                onCancel={handleCancelEditBanner}
                                width={480}
                                footer={[
                                    <button
                                        type={'button'}
                                        onClick={() => editMamaBanner(localId)}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(123, 153, 255)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Сохранить
                                    </button>,
                                    <button
                                        type={'button'}
                                        onClick={handleCancelEditBanner}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(255, 77, 79)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Отмена
                                    </button>,
                                ]}
                            >
                                {editImage || localImage ? (
                                    <div className='banners__pic-item'>
                                        <img src={editImage || localImage} alt='' />
                                        <div className='baners__pic-item--backdrop'>
                                            <Upload
                                                className='baners__pic-item--new'
                                                showUploadList={false}
                                                customRequest={asyncRequest}
                                                listType='picture-card'
                                                onChange={handleEditUpload}
                                            >
                                                <p>Загрузить другую картинку</p>
                                            </Upload>
                                        </div>
                                    </div>
                                ) : (
                                    <Upload
                                        showUploadList={false}
                                        customRequest={asyncRequest}
                                        listType='picture-card'
                                        onChange={handleEditUpload}
                                    >
                                        <p
                                            style={{
                                                height: '150px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Загрузить картинку
                                        </p>
                                    </Upload>
                                )}

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={localText}
                                    onChange={(e) => setLocalText(e.target.value)}
                                    placeholder='Название'
                                    style={{ marginTop: '20px' }}
                                />

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={localUrl}
                                    onChange={(e) => setLocalUrl(e.target.value)}
                                    placeholder='URL'
                                    style={{ marginTop: '20px' }}
                                />
                                <Checkbox
                                    text={'Показывать в приложении'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInApp}
                                    onChange={(e) => setShowInApp(e.target.checked)}
                                    id={'show-app-edit-rect'}
                                />
                                <Checkbox
                                    text={'Показывать на сайте'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInSite}
                                    onChange={(e) => setShowInSite(e.target.checked)}
                                    id={'show-site-edit-rect'}
                                />
                            </Modal>
                            <Modal
                                title='Добавить прямоугольный баннер'
                                open={isModalOpen}
                                onCancel={handleCancel}
                                width={480}
                                footer={[
                                    <button
                                        type={'button'}
                                        onClick={handleRectangularBannerSave}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(123, 153, 255)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Сохранить
                                    </button>,
                                    <button
                                        type={'button'}
                                        onClick={handleCancel}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'rgb(255, 77, 79)',
                                            border: 'none',
                                            color: '#fff',
                                            fontWeight: '600',
                                            padding: '7px 0',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Отмена
                                    </button>,
                                ]}
                            >
                                {tempImage ? (
                                    <div className='banners__pic-item'>
                                        <img src={tempImage} alt='' />
                                        <div className='baners__pic-item--backdrop'>
                                            <Upload
                                                className='baners__pic-item--new'
                                                showUploadList={false}
                                                customRequest={asyncRequest}
                                                listType='picture-card'
                                                onChange={handleUpload}
                                            >
                                                <p>Загрузить другую картинку</p>
                                            </Upload>
                                        </div>
                                    </div>
                                ) : (
                                    <Upload
                                        showUploadList={false}
                                        customRequest={asyncRequest}
                                        listType='picture-card'
                                        onChange={handleUpload}
                                    >
                                        <p
                                            style={{
                                                height: '150px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Загрузить картинку
                                        </p>
                                    </Upload>
                                )}

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={bannerText}
                                    onChange={(e) => setBannerText(e.target.value)}
                                    placeholder='Название'
                                    style={{ marginTop: '20px' }}
                                />

                                <Input
                                    shadow={true}
                                    maskType={String}
                                    value={bannerUrl}
                                    onChange={(e) => setBannerUrl(e.target.value)}
                                    placeholder='URL'
                                    style={{ marginTop: '20px' }}
                                />
                                <Checkbox
                                    text={'Показывать в приложении'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInApp}
                                    onChange={(e) => setShowInApp(e.target.checked)}
                                    id={'show-app-rect'}
                                />
                                <Checkbox
                                    text={'Показывать на сайте'}
                                    style={{ marginTop: '20px' }}
                                    shadow={true}
                                    checked={showInSite}
                                    onChange={(e) => setShowInSite(e.target.checked)}
                                    id={'show-site-rect'}
                                />
                            </Modal>
                        </Modal>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Banners;
